import React, { Component } from "react"
import Contacts from "../components/Contacts"
import Footer from "../components/Footer"
import Head from "../components/Head"

//Image
import EmailGIF from "../static/email.gif"

class AGBPage extends Component {
  render() {
    return (
      <div>
        <Head />
        <div class="container">
          <div class="main">
            <div class="header">
              <div class="title">
                {" "}
                <a href="/">
                  <h1> </h1>
                </a>{" "}
              </div>
            </div>
            <div class="content">
              <div class="item">
                <h1>Unsere Allgemeinen Geschäftsbedingungen (AGB)</h1>
                <br />
                <p>
                  {" "}
                  <span>
                    Katharina Gabber
                    <br />
                    Stangeracker 8<br />
                    85132 Workerszell
                    <br />
                    Tel.: 08421 / 80924 <br />
                    <br />
                    - im folgenden Katharina Gabber genannt - <br />
                    <br />
                    1. Allgemeines/Geltung
                    <br />
                    Diese Allgemeinen Geschäftsbedingungen liegen allen
                    Lieferungen und Leistungen von Katharina Gabber zugrunde.
                    Abweichende und/oder ergänzende Vereinbarungen bedürfen der
                    ausdrücklichen schriftlichen Zustimmung von Katharina
                    Gabber. Eine Zustimmung per eMail ist
                    <br />
                    ausreichend.
                    <br />
                    <br />
                    2. Angebot
                    <br />
                    Alle Angebote von Katharina Gabber auf der Internetseite von
                    www.katharinasrezepte.de sind unverbindlich und
                    freibleibend. Kleine Abweichungen und technische Änderungen
                    gegenüber unseren Abbildungen oder Beschreibungen sind
                    möglich. Der Vertrag kommt durch den Abschluss des
                    Bestellvorgangs auf der Internetseite von Katharina Gabber
                    und Bestätigung der Bestellung durch Katharina Gabber per
                    E-Mail oder Ausführung der Bestellung durch Katharina Gabber
                    zustande.
                    <br />
                    <br />
                    3. Preise und Zahlungsbedingungen
                    <br />
                    Es gelten die auf den Internetseiten von Katharina Gabber
                    dargestellten Preise zum Zeitpunkt der Bestellung. Alle
                    genannten Preise sind Endpreise in Euro und enthalten die
                    gesetzliche Mehrwertsteuer in Höhe von derzeit 7 Prozent.
                    Offensichtliche Preisirrtümer vorbehalten. Verpackungs- und
                    Versandkosten werden zuzüglich berechnet. Katharina Gabber
                    akzeptiert alle auf der Internetseite von Katharina Gabber
                    innerhalb des
                    <br />
                    Bestellvorgangs angebotenen Zahlungsarten. Durch die
                    Zahlungsart (eventuell) zusätzlich entstehende Kosten (z.B.
                    Nachnahmegebühr) werden ebenfalls zuzüglich berechnet. Eine
                    Verrechnung gegenseitiger Ansprüche ist nur mit
                    ausdrücklicher schriftlicher Zustimmung von Katharina Gabber
                    möglich.
                    <br />
                    <br />
                    4. Lieferung, Lieferzeiten und Versandkosten
                    <br />
                    Die Lieferung der Ware an den Kunden erfolgt durch die
                    innerhalb des Bestellvorgangs angegebenen Drittanbieter
                    (Lieferdienste). Der Versand der Bücher erfolgt per
                    &#8222;Büchersendung&#8220;. Die Versandkosten
                    <br />
                    werden dem Besteller vor Auslösen des Bestellvorganges
                    innerhalb des Warenkorbes bekannt gegeben. Am Lager
                    befindliche Ware wird innerhalb von drei Werktagen nach
                    Bestelleingang zum Versand gebracht.
                    <br />
                    Für nicht am Lager befindliche Ware kann die Lieferzeit bis
                    zu vier Wochen nach Bestelleingang betragen. Für die
                    Liefermöglichkeit ist Katharina Gabber von der rechtzeitigen
                    Selbstbelieferung abhängig. Scheitert die Lieferung aus
                    Gründen, die Katharina Gabber nicht zu vertreten hat, so
                    kann Katharina Gabber vom Vertrag zurücktreten. Katharina
                    Gabber verpflichtet sich, den Kunden rechtzeitig darüber zu
                    informieren. Etwaig geleistete Zahlungen werden unverzüglich
                    zurückerstattet. In diesem Fall steht dem Kunden ein Recht
                    auf Schadensersatz nicht zu. Teillieferungen sind zulässig,
                    soweit nicht der Kunde erkennbar kein Interesse an ihnen hat
                    oder ihm diese erkennbar nicht zumutbar sind. Die Kosten für
                    Transport und Verpackung werden in diesem Fall nur einmal
                    berechnet.
                    <br />
                    <br />
                    5. Belehrung über das Umtausch- und Rückgaberecht
                    <br />
                    Alle bei Katharina Gabber erworbenen Artikel können
                    innerhalb von zwei Wochen nach Erhalt der Ware, ohne Angabe
                    von Gründen, zurückgegeben werden. Die Frist beginnt
                    frühestens mit Erhalt der Ware und dieser Belehrung. Zur
                    Wahrung der Frist genügt das rechtzeitige Absenden der Ware.
                    Das Rückgaberecht besteht nach § 312d IV BGB nicht bei
                    entsiegelten Audio-, Video- oder Softwaredatenträgern oder
                    bei Lieferung von Waren, die nach Kundenspezifikationen
                    angefertigt worden sind (z.B. Sonderbestellungen, die
                    speziell geordert werden müssen). Vom Vertragspartner
                    geleistete Zahlungen werden innerhalb von 5 Werktagen nach
                    Erhalt der zurückgesendeten Ware per Überweisung auf ein vom
                    Kunden zu benennendes Konto in Deutschland geleistet. Die
                    Rücksendung hat an die oben genannte Firmenanschrift zu
                    erfolgen. Bis zu einem Warenwert von 40 Euro erfolgt die
                    Rücksendung auf Kosten und Gefahr des Kunden. Die
                    Rücksendekosten ab einem Bestellwert in Höhe von 40 Euro
                    werden von Katharina Gabber übernommen. Die Rücksendung muss
                    ausreichend frankiert sein. Die Portokosten für die
                    Rücksendung (auf Basis der günstigsten möglichen Versandart
                    der Deutschen Post AG) werden innerhalb von 5 Werktagen nach
                    Eingang der Ware auf ein vom Kunden zu benennendes Konto in
                    Deutschland erstattet. Entstehen Katharina Gabber
                    zusätzliche Kosten durch eine unzureichende Frankierung der
                    Rücksendung, so werden diese Kosten mit etwaigen geleisteten
                    Zahlungen des Kunden verrechnet. Katharina Gabber behält
                    sich einen Ersatzanspruch für beschädigte Ware ausdrücklich
                    vor. Hat der Kunde eine Verschlechterung, den Untergang oder
                    eine anderweitige Unmöglichkeit zu vertreten, so hat er die
                    Wertminderung zu ersetzen; §§ 351 und 353 BGB sind nicht
                    anzuwenden. Die Rücksendung der Ware hat in der
                    Originalverpackung zu erfolgen. Sollte dies nicht möglich
                    sein, so muss der Kunde für eine Verpackung sorgen, welche
                    eine Beschädigung der Ware ausschließt. Für Schäden an der
                    Ware, die auf eine unzureichende Verpackung zurückzuführen
                    ist, haftet der Kunde. Offensichtliche Mängel (insbesondere
                    Beschädigungen, Falschlieferungen oder Mengenabweichungen),
                    Transportschäden oder sonstige Mängel müssen Katharina
                    Gabber unverzüglich, spätestens jedoch innerhalb einer Frist
                    von 14 Kalendertagen nach Erhalt der Ware schriftlich
                    mitgeteilt werden. Die Mitteilung per eMail ist ausreichend.
                    <br />
                    <br />
                    6. Gefahrenübergang (entfällt bei Abschluss einer
                    Versicherung)
                    <br />
                    Die Lieferung der Ware erfolgt auf Gefahr des Kunden. Die
                    Gefahr geht mit Übergabe der Ware an das
                    Transportunternehmen auf den Kunden über. Dies gilt auch,
                    wenn Katharina Gabber die Transportkosten übernommen hat.
                    Beanstandungen wegen Transportschäden (z.B. beschädigte
                    Verpackung beim Empfang der Ware) hat der Kunde unmittelbar
                    gegenüber dem Transportunternehmen innerhalb der dafür
                    vorgesehenen Fristen geltend zu machen.
                    <br />
                    <br />
                    7. Gewährleistung
                    <br />
                    Katharina Gabber gewährleistet, dass die verkaufte Ware zum
                    Zeitpunkt des Gefahrenübergangs frei von Material- und
                    Fabrikationsfehlern ist und die vertraglich zugesicherten
                    Eigenschaften hat. Es gilt die gesetzliche
                    Gewährleistungsfrist von zwei Jahren ab Rechnungsstellung.
                    Die Gewährleistung erstreckt sich nicht auf den
                    betriebsgewöhnlichen Verschleiß bzw. die normale Abnutzung.
                    Ansprüche des Kunden auf Schadensersatz, z.B. wegen
                    Nichterfüllung, Verschulden bei Vertragsschluss, Verletzung
                    vertraglicher Nebenverpflichtungen, Mangelfolgeschäden,
                    Schäden aus unerlaubter Handlung und sonstigen Rechtsgründen
                    sind ausgeschlossen. Ausnehmend davon haftet Katharina
                    Gabber beim Fehlen einer zugesicherten Eigenschaft, bei
                    Vorsatz oder grober Fahrlässigkeit. Ansprüche aus dem
                    Produkthaftungsgesetz sind davon nicht betroffen. Sollten
                    Mängel auftreten, die Katharina Gabber zu vertreten hat, und
                    ist im Falle des Umtausches der Ware auch die
                    Ersatzlieferung mangelhaft, so steht dem Käufer das Recht
                    auf Wandlung oder Minderung zu. Katharina Gabber übernimmt
                    eine Haftung weder für die ständige und ununterbrochene
                    Verfügbarkeit von www.katharinasrezepte.de noch für
                    technische oder elektronische Fehler des Online-Angebots.
                    <br />
                    <br />
                    8. Eigentumsvorbehalt
                    <br />
                    Alle Lieferungen erfolgen unter Eigentumsvorbehalt. Die
                    gelieferte Ware bleibt bis zur vollständigen Bezahlung des
                    Kaufpreises Eigentum von Katharina Gabber.
                    <br />
                    <br />
                    9. Datenspeicherung/Datenschutz
                    <br />
                    Gemäß §28 BDSG (Bundesdatenschutzgesetz) machen wir Sie
                    darauf aufmerksam, dass die im Rahmen der
                    Geschäftsabwicklung notwendigen Daten mittels einer
                    EDV-Anlage gemäß § 33 BDSG verarbeitet und gespeichert
                    werden. Sämtliche vom Kunden erhobenen persönlichen Daten
                    werden vertraulich behandelt. Ausschließlich im Rahmen der
                    Bestellabwicklung (Zahlung, Versand) werden die notwendigen
                    Daten auch gegenüber Dritten verwendet. Jederzeit können Sie
                    unentgeltlich Auskunft über die über Sie gespeicherten Daten
                    erhalten. Senden Sie dazu eine eMail. Aus Datenschutzgründen
                    kann die Beantwortung der Mail nur an die im Impressum
                    hinterlegte eMail-Adresse erfolgen.
                    <br />
                    <br />
                    10. Gerichtsstand
                    <br />
                    Gerichtsstand für alle Streitigkeiten im Zusammenhang mit
                    dem Liefergeschäft ist, soweit vereinbar, der Gerichtsstand
                    von Katharina Gabber. Der Sitz von Katharina Gabber wird als
                    Gerichtsstand vereinbart für den Fall, dass der
                    Vertragspartner keinen allgemeinen Gerichtsstand in
                    Deutschland hat oder der Vertragspartner seinen Wohnsitz
                    nach Vertragsschluss ins Ausland verlegt oder der Wohnsitz
                    des Vertragspartners zum Zeitpunkt der Klageerhebung nicht
                    bekannt ist. Die Vertragspartner vereinbaren hinsichtlich
                    sämtlicher Rechtsbeziehungen aus diesem Vertragsverhältnis
                    die Anwendung des Rechts der Bundesrepublik Deutschland
                    unter Ausschluss des UN-Kaufrechts.
                    <br />
                    <br />
                    11. Impressum
                    <br />
                    Katharina Gabber
                    <br />
                    Stangeracker 8<br />
                    85132 Workerszell
                    <br />
                    Tel.: 08421 / 80924
                    <br />
                  </span>{" "}
                  <span>
                    E-Mail:{" "}
                    <img
                      src={EmailGIF}
                      align="absbottom"
                      height="20"
                      width="275"
                      alt=""
                    />
                  </span>
                  <br />
                  <span>
                    {" "}
                    <br />
                    12. Zusatzbestimmung
                    <br />
                    Die Nichtigkeit oder Unwirksamkeit einzelner Bestimmungen
                    dieser AGB berührt nicht die Gültigkeit der übrigen
                    Bestimmungen. Nichtige oder unwirksame Bedingungen werden
                    durch die gesetzlichen Bestimmungen ersetzt.
                    <br />
                    <br />
                    Hinweise Installation:
                    <br />
                    Durch den Befehl &lt;Datei&gt; &lt;Speichen unter..&gt; in
                    Ihrem Browser können Sie die AGB's lokal speichern.
                    <br />
                    <br />
                    Hinweis Drucken:
                    <br />
                    Durch den Befehl &lt;Datei&gt; &lt;Drucken&gt; in Ihrem
                    Browser können Sie die AGB's ausdrucken.
                  </span>
                  <br />
                </p>
              </div>
            </div>
            <Contacts />
            <div class="clearer">
              <span />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default AGBPage
